/* global $ */
import Form from './form';
import BaseClass from '../base/baseClass';

class Forms extends BaseClass
{
    forms = {};

    init() {
        $.each($('form.js-validate'), (i, item) => {
            this.initForm($(item).attr('id'));
        });
    }

    initForm(id)
    {
        this.forms[id] = new Form('#' + id, '#' + id + '-messages');
    }
}

export default Forms;