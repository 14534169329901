/* global $ */
import BaseClass from '../base/baseClass';
import {Tooltip} from 'bootstrap';

class App extends BaseClass
{
    init()
    {
        this.initNavOpen();
        $('body').css('opacity', 1);
        this.initTooltips();
        console.log('App initialized');
    }

    initTooltips()
    {
        $.each($('[data-bs-toggle="tooltip"]'), function (i, elem) {
            new Tooltip(elem);
        });
    }

    initNavOpen() {
        var navOpen = function() {
            if ($('#navbar-toggler').hasClass('collapsed')) {
                $('#header').removeClass('expanded');
            } else {
                $('#header').addClass('expanded');
            }
        };
        navOpen();
        $('#navbar-toggler').click(function() {
            navOpen();
        });
    }
}

export default App;