/* global $ */

class BaseClass
{
    constructor()
    {
        let args = arguments;
        $(() => {
            this.init(...args);
        });
    }
}

export default BaseClass;