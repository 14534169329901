/* global $ */
import 'slick-carousel';
import BaseClass from '../base/baseClass';

class Slick extends BaseClass
{
    init() {
        console.log('Slick initialized');
        $('.slick-carousel').slick({
            autoplay: true,
        });
        $('.highlight-slick-carousel').slick({
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
        $('.news-slick-carousel').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            infinite: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
        $('.gallery-slick-carousel').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            infinite: true,
            asNavFor: '.thumb-slick-carousel',
        });
        $('.thumb-slick-carousel').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.gallery-slick-carousel',
            arrows: true,
            focusOnSelect: true,
            infinite: true,
        });
    }
}

export default Slick;