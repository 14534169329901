/**
 * Import here any npm modules and your own js/scss
 * You can import npm modules as css, scss or js
 * By importing scss you give yourself the ability to override the variables through resources.scss
 */

/**************
 * Javascript
 **************/

//Npm Libraries
import 'lazysizes';
import 'bootstrap';

//App
import './js/app.js';

/**************
 * Css
 **************/

//Npm Libraries
import "bootstrap/scss/bootstrap.scss";
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.scss';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import 'animate.css';

//App
import "./css/app/base/html.scss";
import "./css/app/base/typography.scss";
import "./css/app/components/home.scss";
import "./css/app/components/testimonials.scss";
import "./css/app/components/team.scss";
import "./css/app/components/before-afters.scss";
import "./css/app/components/treatments.scss";
import "./css/app/components/fee-guide.scss";
import "./css/app/components/news.scss";
import "./css/app/components/contact.scss";
import "./css/app/components/slick.scss";
import "./css/app/components/header.scss";
import "./css/app/components/footer.scss";
import "./css/app/components/blog.scss";
import "./css/app/components/forms.scss";